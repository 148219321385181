// Импортируем другие js-файлы
function ready() {
  setTimeout(() => {
    const el = document.getElementById('sticky-scroll')
    el.classList.add('sticky-scroll-visible')
  }, 2000)
}

function onScroll(e) {
  const el = document.getElementById('sticky-scroll')
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
  const cH = document.body.clientHeight
  const sH = document.body.scrollHeight

  if (scrollTop + cH === sH) {
    el.classList.remove('sticky-scroll-visible')
  } else {
    el.classList.add('sticky-scroll-visible')
  }
}

function ScrollToBottom() {
  window.scrollTo({
    top: 1000,
    behavior: "smooth"
});
}

document.addEventListener('DOMContentLoaded', ready);

document.addEventListener('scroll', onScroll)